import styled, { css, keyframes } from 'styled-components'
import IconProps from '../interfaces/IconProps'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const speeds = {
  normal: 1.5,
  fast: 0.75,
}

const sizes = {
  small: '1em',
  medium: '3em',
  large: '6em',
}

const animation = css`
  animation: ${rotate}
    ${({ spin }: IconProps) =>
      typeof spin === 'string' ? speeds[spin] : speeds.normal}s
    linear infinite;
`

const Icon = styled.i`
  width: ${({ size = 'small' }: IconProps) => sizes[size]};
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
  transform-origin: center center;
  ${({ spin }: IconProps) => (spin ? animation : undefined)}
`

export default Icon
