import React from 'react'
import styled from 'styled-components'

const StyledFooter = styled.footer`
  background-color: #005060;
  color: #fff;
  text-align: center;
  padding: 20px;
  margin-bottom: 0;
  justify-self: flex-end;
`

function Footer() {
  return (
    <StyledFooter>Copyright © iBoxen {new Date().getFullYear()}</StyledFooter>
  )
}

export default Footer
